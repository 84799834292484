// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getStorage} from "firebase/storage";
import { getFirestore } from "firebase/firestore";

const firebaseConfig = {
  apiKey: "AIzaSyBctJGiyqUX4q7ZQ5oI11LuecGht22mE1o",
  authDomain: "foknje7ik.firebaseapp.com",
  projectId: "foknje7ik",
  storageBucket: "foknje7ik.appspot.com",
  messagingSenderId: "527795897562",
  appId: "1:527795897562:web:93514cbb19ba0b083ed1e3",
  measurementId: "G-E1LTQCWK2C"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const fileDB = getStorage(app);
const txtDB = getFirestore(app);


export{fileDB, txtDB}
